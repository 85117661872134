// ドラッグアンドドロップのポインタが body に入ったときに、ファイルのドロップを促す表示をする
import $ from 'jquery';
import { onConnect } from '@sonicgarden/onconnect';

let timer = -1;
let isDragging = false;

const onBodyDrop = (e: Event) => {
  if (document.querySelector('.js-file-drop-target')) {
    e.preventDefault();
  }
  isDragging = false;
  const { body } = document;
  body.classList.remove('is-dragging');
};

const onBodyDragEnter = (e: Event) => {
  e.preventDefault();
  isDragging = true;

  const { body } = document;
  body.classList.add('is-dragging');
};

const onBodyDragOver = (e: Event) => {
  e.preventDefault();
  isDragging = true;
};

const onBodyDragLeave = (e: Event) => {
  e.preventDefault();
  const { body } = document;
  isDragging = false;

  // ポインタ重なるDOM要素(bodyの子孫)が変わったときに
  // 1. 新しくポインタが重なったDOM要素の dragenter
  // 2. 今までポインタが重なっていたDOM要素の dragleave
  // 3. 新しくポインタが重なったDOM要素の dragover
  // の順でイベントが発生する。
  // dragleave ですぐに is-dragging を削除してしまうと、まだポインタが body の子孫要素にあるのにドラッグアンドドロップ終了のように見えてしまう。
  // ポインタが完全に body から外れた場合 dragover が発生しないので、少しの時間待ってから is-dragging を削除する。
  clearTimeout(timer);
  timer = setTimeout(() => {
    if (!isDragging) {
      body.classList.remove('is-dragging');
    }
  }, 200);
};

const onDrop = (e: DragEvent) => {
  const scope = (e.currentTarget as HTMLElement).closest('.js-file-drop-scope, form');
  const input = scope?.querySelector<HTMLInputElement>('.js-file-drop-input');
  if (input) {
    const files = e.dataTransfer?.files;
    if (files) {
      input.files = files;
      input.dispatchEvent(new Event('change'));
    }
  }
};

const addBodyHandlers = () => {
  const { body } = document;
  body.addEventListener('drop', onBodyDrop);
  body.addEventListener('dragenter', onBodyDragEnter);
  body.addEventListener('dragleave', onBodyDragLeave);
  body.addEventListener('dragover', onBodyDragOver);
};

const removeBodyHandlers = () => {
  const { body } = document;
  body.removeEventListener('drop', onBodyDrop);
  body.removeEventListener('dragenter', onBodyDragEnter);
  body.removeEventListener('dragleave', onBodyDragLeave);
  body.removeEventListener('dragover', onBodyDragOver);
};

onConnect('.js-file-drop-target', (input) => {
  input.addEventListener('drop', onDrop);
  return () => input.removeEventListener('drop', onDrop);
});

$(document).on('turbolinks:before-cache', removeBodyHandlers);
$(document).on('turbolinks:load', addBodyHandlers);
