import { onConnect } from '@sonicgarden/onconnect';

function onChangeFileInput(event: Event) {
  const inputElement = event.currentTarget as HTMLInputElement;
  if (inputElement.files == null) return;

  const file = inputElement.files[0];
  if (file == null) return;

  const scope = inputElement.closest('.js-file-input-scope');
  if (scope == null) return;

  const fileNameInput = scope.querySelector<HTMLInputElement>('.js-file-input-file-name');
  if (fileNameInput == null) return;

  fileNameInput.value = file.name;
}

onConnect('.js-file-input', (input) => {
  input.addEventListener('change', onChangeFileInput);
  return () => input.removeEventListener('change', onChangeFileInput);
})
